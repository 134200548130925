exports.components = {
  "component---src-components-ubx-viewer-document-viewer-tsx": () => import("./../../../src/components/ubxViewer/DocumentViewer.tsx" /* webpackChunkName: "component---src-components-ubx-viewer-document-viewer-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-en-form-thank-you-tsx": () => import("./../../../src/pages/en/form-thank-you.tsx" /* webpackChunkName: "component---src-pages-en-form-thank-you-tsx" */),
  "component---src-pages-en-ir-news-tsx": () => import("./../../../src/pages/en/ir-news/[...].tsx" /* webpackChunkName: "component---src-pages-en-ir-news-tsx" */),
  "component---src-pages-en-profile-tsx": () => import("./../../../src/pages/en/profile.tsx" /* webpackChunkName: "component---src-pages-en-profile-tsx" */),
  "component---src-pages-en-redirect-blocked-tsx": () => import("./../../../src/pages/en/redirect/blocked.tsx" /* webpackChunkName: "component---src-pages-en-redirect-blocked-tsx" */),
  "component---src-pages-en-search-tsx": () => import("./../../../src/pages/en/search.tsx" /* webpackChunkName: "component---src-pages-en-search-tsx" */),
  "component---src-pages-en-u-blox-latest-news-tsx": () => import("./../../../src/pages/en/u-blox-latest-news/[...].tsx" /* webpackChunkName: "component---src-pages-en-u-blox-latest-news-tsx" */),
  "component---src-pages-en-ubx-includable-footer-tsx": () => import("./../../../src/pages/en/ubx_includable_footer.tsx" /* webpackChunkName: "component---src-pages-en-ubx-includable-footer-tsx" */),
  "component---src-pages-en-ubx-includable-header-tsx": () => import("./../../../src/pages/en/ubx_includable_header.tsx" /* webpackChunkName: "component---src-pages-en-ubx-includable-header-tsx" */),
  "component---src-pages-zh-form-thank-you-tsx": () => import("./../../../src/pages/zh/form-thank-you.tsx" /* webpackChunkName: "component---src-pages-zh-form-thank-you-tsx" */),
  "component---src-pages-zh-profile-tsx": () => import("./../../../src/pages/zh/profile.tsx" /* webpackChunkName: "component---src-pages-zh-profile-tsx" */),
  "component---src-pages-zh-redirect-blocked-tsx": () => import("./../../../src/pages/zh/redirect/blocked.tsx" /* webpackChunkName: "component---src-pages-zh-redirect-blocked-tsx" */),
  "component---src-pages-zh-search-tsx": () => import("./../../../src/pages/zh/search.tsx" /* webpackChunkName: "component---src-pages-zh-search-tsx" */),
  "component---src-pages-zh-ubx-includable-footer-tsx": () => import("./../../../src/pages/zh/ubx_includable_footer.tsx" /* webpackChunkName: "component---src-pages-zh-ubx-includable-footer-tsx" */),
  "component---src-pages-zh-ubx-includable-header-tsx": () => import("./../../../src/pages/zh/ubx_includable_header.tsx" /* webpackChunkName: "component---src-pages-zh-ubx-includable-header-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-casestudy-tsx": () => import("./../../../src/templates/casestudy.tsx" /* webpackChunkName: "component---src-templates-casestudy-tsx" */),
  "component---src-templates-company-tsx": () => import("./../../../src/templates/company.tsx" /* webpackChunkName: "component---src-templates-company-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-homepage-tsx": () => import("./../../../src/templates/homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-tsx" */),
  "component---src-templates-info-tsx": () => import("./../../../src/templates/info.tsx" /* webpackChunkName: "component---src-templates-info-tsx" */),
  "component---src-templates-location-tsx": () => import("./../../../src/templates/location.tsx" /* webpackChunkName: "component---src-templates-location-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-partnership-tsx": () => import("./../../../src/templates/partnership.tsx" /* webpackChunkName: "component---src-templates-partnership-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-productoverview-tsx": () => import("./../../../src/templates/productoverview.tsx" /* webpackChunkName: "component---src-templates-productoverview-tsx" */),
  "component---src-templates-technology-tsx": () => import("./../../../src/templates/technology.tsx" /* webpackChunkName: "component---src-templates-technology-tsx" */),
  "component---src-templates-usecase-tsx": () => import("./../../../src/templates/usecase.tsx" /* webpackChunkName: "component---src-templates-usecase-tsx" */),
  "component---src-templates-webinar-tsx": () => import("./../../../src/templates/webinar.tsx" /* webpackChunkName: "component---src-templates-webinar-tsx" */),
  "component---src-templates-whitepaper-tsx": () => import("./../../../src/templates/whitepaper.tsx" /* webpackChunkName: "component---src-templates-whitepaper-tsx" */),
  "slice---src-components-footer-tsx": () => import("./../../../src/components/Footer.tsx" /* webpackChunkName: "slice---src-components-footer-tsx" */),
  "slice---src-components-header-tsx": () => import("./../../../src/components/Header.tsx" /* webpackChunkName: "slice---src-components-header-tsx" */)
}

